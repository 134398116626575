import { useState, useEffect } from 'react'
import { Button, TextField } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { useStyles } from './Actualitescss'
import { getRandomFiche } from '../../services/serviceRest'
import Axios from 'axios'
import ListFiches from '../../components/lists/listFiches/ListFiches'
import FicheModal from '../../components/modals/FicheModal/FicheModal'
import moment from 'moment'

export const Actualites = () => {
  const classes = useStyles()
  const [persons, setFiches] = useState([])
  const [currentFiche, setCurrentFiche] = useState(null)
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)
  const [newFiche, setNewFiche] = useState(false)
  const [ficheEdited, setFicheEdited] = useState(false)
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    getFiches()
  }, [ficheEdited])

  const getFiches = (e) => {
    getRandomFiche('ACTUALITE').then((rep) => {
      
      if(e && e !== '')
        rep = rep.filter((data) => {return data.Titre.toLowerCase().includes(e.toLowerCase());})

      console.log(rep, e);
      //rep = rep.filter((data) => {return data.quoi.includes(e);}).map
      rep = rep.map((data) => {
        data.id = data.ID
        data.quoi = data.Titre
        data.sourceinformation = data.Source
        data.datesaisie = moment(data.DateSaisie).format('DD.MM.YYYY') //data.DateSaisie;
        data.dateecheance = data.DateEcheance
        data.remarque = data.Remarque
        data.statut = data.Statut

        //delete data.ID;
        return data
      })
      console.log('rep =======>>>>>>>>>>> ', e, rep);
      //setPersons(rep);
      setFiches(rep)
    })
  }

  const addFiche = (pers, table) => {
    console.log('passe dans axios insert', pers, table)
    var table = 'ACTUALITE'
    Axios.put('/api/insert', {
      quoi: pers.quoi,
      sourceinformation: pers.sourceinformation,
      datesaisie: moment(pers.dateSaisie).format('YYYY-MM-DD HH:mm:ss'), //pers.datesaisie,//inversion du jour et du mois sinon ne fonctionne pas.
      dateecheance: pers.dateecheance,
      remarque: pers.remarque,
      statut: pers.statut,
      table: table
    }).then(() => {
      setCurrentFiche()
      //console.log(currentFiche)
      setSearchText('')
      getFiches()

      alert('Enregistrement effectué avec succès')
    })
  }

  const editFiche = (pers, table) => {
    var table = 'ACTUALITE' //MI 08.02.2023
    Axios.put('/api/update', {
      id: pers.id,
      quoi: pers.quoi,
      sourceinformation: pers.sourceinformation,
      datesaisie: moment(pers.datesaisie).format('YYYY-MM-DD HH:mm:ss'),
      dateecheance: pers.dateecheance,
      remarque: pers.remarque,
      statut: pers.statut,
      table: table,
    }).then((response) => {
      alert('Modification effectuée')
    })
  }

  const deleteRandomPerson = (pers, table) => {
    console.log('passe dans axios delete actualité', pers, table)
    var table = 'ACTUALITE'
    Axios.delete(`/api/delete/${pers}`,{data:{table: table}}).then(() => {
      setSearchText('')
      getFiches()
      alert('Suppression effectuée avec succès')
    })
  }

  const newLine = () => {
    setNewFiche(true)
    setCurrentFiche(null)
    setIsDetailsOpen(true)
  }

   const handleSearchChange = (event) => {
     let value = event.target.value
    setSearchText(value)
    getFiches(value)
    //console.log(searchText);
  }

//console.log(searchText);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Grid container>
            <Grid item xs={10}>
              <br />
              <h2>Liste des actualités</h2>
            </Grid>
            <Grid item xs={2} style={{ justifyContent: 'flex-end' }}>
              <br />
              <br />
              <Button variant="contained" onClick={newLine}>
                Ajouter une actualité
              </Button>

            </Grid>
            <Grid container spacing={1} style={{ width: '60%' }}>
             <TextField
              
              id="vSearch"
              name="vSearch"
              label="Rechercher"
              type="text"
              fullWidth
              value={searchText}
              onChange={handleSearchChange}
            />
            </Grid>
          </Grid>
          <br />
          <br />
          <ListFiches
            setCurrentFiche={setCurrentFiche}
            deleteRandomPerson={deleteRandomPerson}
            getFiches={getFiches}
            persons={persons}
            currentFiche={currentFiche}
            setFicheEdited={setFicheEdited}
            setNewFiche={setNewFiche}
            tableFiche={'ACTUALITE'}
            setSearchText={setSearchText}

            /* {...{
              setCurrentFiche,
            //setIsDetailsOpen,
              deleteRandomPerson,
              getFiches,
              persons,
              currentFiche,
              setFicheEdited
            }} */
          />
          {newFiche === true && (
            <FicheModal
              currentFiche={currentFiche}
              setCurrentFiche={setCurrentFiche}
              isDetailsOpen={isDetailsOpen}
              setIsDetailsOpen={setIsDetailsOpen}
              editFiche={editFiche}
              addFiche={addFiche}
              setNewFiche={setNewFiche}
              tableFiche={'ACTUALITE'}
              setSearchText={setSearchText}
            />
          )}
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </div>
  )
}
