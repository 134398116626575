import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles({
  GridContainer:{
    display:"flex",
    justifyContent:"center",
    height:"60vh",
    width:"150vh",
    flexGrow:1
  },
  Table100:{
    width:"100%!important"
  },
})