import { colors } from '@material-ui/core'
import { grey, red } from '@material-ui/core/colors'
import { makeStyles, rgbToHex } from '@material-ui/core/styles'
export const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  Header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  intro: {
    border: 'solid',
    borderColor: 'black',
    color: 'green',
    padding: 10,
  },
})
