import { useState, useEffect } from 'react'
import { useStyles } from './Compagnies.css'
import Grid from '@material-ui/core/Grid'
import { Button, TextField } from '@material-ui/core'
import ListFiches from '../../components/lists/listFiches/ListFiches'
import { getRandomFiche } from '../../services/serviceRest'
import moment from 'moment'
import FicheModalCompagnies from '../../components/modals/FicheModal/FicheModalCompagnies'
import Axios from 'axios'

export const Compagnies = () => {
  const classes = useStyles()
  const [fiche, setFiches] = useState([])
  const [currentFiche, setCurrentFiche] = useState(null)
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)
  const [newFiche, setNewFiche] = useState(false)
  const [ficheEdited, setFicheEdited] = useState(false)
  const [searchText, setSearchText] = useState('');
  //const [typeFiche, setTypeFiche] = useState('')

  useEffect(() => {
    getFiches()
  }, [ficheEdited])

  const getFiches = (e) => {
    console.log('getFiches =====>>>>', e)
    getRandomFiche('COMPAGNIES').then((rep) => {
      console.log('getRandomFiche ====>>',rep, e)

       if(e && e !== '')
        rep = rep.filter((data) => {return data.Societe.toLowerCase().includes(e.toLowerCase());})

      rep = rep.map((data) => {
        data.id = data.ID
        data.quoi = data.Societe
        data.secteur = data.Secteur
        data.adresse = data.Adresse
        data.siteinternet = data.SiteInternet
        data.telephone = data.Telephone
        data.emailcontact = data.EmailContact
        data.complement = data.Complement
        data.dateadhesion = moment(data.DateAdhesion).format('DD.MM.YYYY')
        data.nomcontact = data.NomContact
        data.prenomcontact = data.PrenomContact
        data.nbemploye = data.NbEmploye
    
      //data.dateecheance = data.DateEcheance
        // data.statut = data.Statut

        return data
      })
      setFiches(rep)
    })
    // getRandomFiche().then((rep) => {
    //   rep = rep.map((data) => {
    //     data.id = data.ID
    //     data.quoi = data.Titre
    //     data.sourceinformation = data.Source
    //     data.datesaisie = moment(data.DateSaisie).format('DD.MM.YYYY') //data.DateSaisie;
    //     data.dateecheance = data.DateEcheance
    //     data.remarque = data.Remarque
    //     data.statut = data.Statut
    //     //delete data.ID;
    //     return data
    //   })
    //   //setPersons(rep);
    //   setFiches(rep)
    // })
  }

  //MI 03.03.2023
  const addFiche = (pers, table) => {
    console.log(pers.dateadhesion, table )
    var table = 'COMPAGNIES'
    Axios.put('/api/insert', {
      quoi: pers.quoi,
      secteur: pers.secteur,
      datesaisie: moment(pers.dateSaisie).format('YYYY-DD-MM'), //pers.datesaisie,//inversion du jour et du mois sinon ne fonctionne pas.
      dateecheance: pers.dateecheance,
      remarque: pers.remarque,
      statut: pers.statut,
      table: table,
      siteinternet: pers.siteinternet, //MI 13.03.23
      adresse: pers.adresse,
      emailcontact: pers.emailcontact,
      telephone: pers.telephone,
      complement: pers.complement,
      nbemploye: pers.nbemploye,
      montantCotisation: 0,
      dateadhesion: moment(pers.dateadhesion,'DD.MM.YYYY').format('YYYY-MM-DD'),
      titreContact: '',
      nomcontact: pers.nomcontact,
      prenomcontact: pers.prenomcontact,
    }).then(() => {
      setCurrentFiche()
      //console.log(currentFiche)
    setSearchText('')
     getFiches()

      alert('Enregistrement effectué avec succès')
    })
  }

  const editFiche = (pers, table) => {
      console.log('Fiche modifiée ====>>',pers.emailcontact)
    var table = 'COMPAGNIES' //MI 03.03.2023
    Axios.put('/api/update', {
     
      id: pers.id,
      quoi: pers.quoi,
      secteur: pers.secteur,
      datesaisie: moment(pers.dateSaisie).format('YYYY-DD-MM HH:mm:ss'),
      dateecheance: pers.dateecheance,
      remarque: pers.remarque,
      statut: pers.statut,
      tableName: table,
      siteinternet: pers.siteinternet, //MI 13.03.23
      adresse: pers.adresse,
      emailcontact: pers.emailcontact,
      telephone: '',
      complement: pers.complement,
      nbemploye: pers.nbemploye,
      montantCotisation: 0,
      dateadhesion: moment(pers.dateadhesion).format('YYYY-DD-MM HH:mm:ss'),
      titreContact: '',
      nomcontact: pers.nomcontact,
      prenomcontact: pers.prenomcontact,
    }).then((response) => {
      
      alert('Modification effectuée')
    })
  }

    const deleteRandomPerson = (pers, table) => {
    console.log('passe dans axios delete actualité', pers, table)
    var table = 'COMPAGNIES'
    Axios.delete(`/api/delete/${pers}`,{data:{table: table}}).then(() => {
      setSearchText('')
      getFiches()
      alert('Suppression effectuée avec succès')
    })
  }

  const newLine = () => {
    setNewFiche(true)
    setCurrentFiche(null)
    setIsDetailsOpen(true)
  }

  const handleSearchChange = (event) => {
    let value = event.target.value
   setSearchText(value) // value.length > 2 && 
   getFiches(value) 
  }

  // const columns = [
  //   { field: 'id', headerName: 'ID', flex: 0.4 },
  //   { field: 'quoi', headerName: 'Societe', flex: 1.5 },
  //   { field: 'sourceinformation', headerName: 'Secteur', flex: 1 },
  //   { field: 'datesaisie', headerName: 'Adhésion le', flex: 1 },
  // ]

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={1}></Grid>

        <Grid item xs={10}>
          <Grid container>
            <Grid item xs={10}>
              <h2>Liste des entreprises</h2>
            </Grid>
            <Grid item xs={2} style={{ justifyContent: 'flex-end' }}>
              <br />
              <br />
              <Button variant="contained" onClick={newLine}>
                Ajouter une entreprise
              </Button>
            </Grid>
            <Grid container spacing={1} style={{ width: '60%' }}>
             <TextField
              
              id="vSearch"
              name="vSearch"
              label="Rechercher"
              type="text"
              fullWidth
              value={searchText}
              onChange={handleSearchChange}
            />
            </Grid>
          </Grid>
          <br />
          <br />
          <ListFiches
            setCurrentFiche={setCurrentFiche}
            deleteRandomPerson={deleteRandomPerson}
            getFiches={getFiches}
            persons={fiche}
            currentFiche={currentFiche}
            setFicheEdited={setFicheEdited}
            setNewFiche={setNewFiche}
            tableFiche={'COMPAGNIES'}
            setSearchText={setSearchText}

            //columns={columns}
          />
          {newFiche === true && (
            <FicheModalCompagnies
              currentFiche={currentFiche}
              setCurrentFiche={setCurrentFiche}
              isDetailsOpen={isDetailsOpen}
              setIsDetailsOpen={setIsDetailsOpen}
              editFiche={editFiche}
              addFiche={addFiche}
              setNewFiche={setNewFiche}
              tableFiche={'COMPAGNIES'}
              setSearchText={setSearchText}
            />
          )}
        </Grid>

        <Grid item xs={1}></Grid>
      </Grid>
    </div>
  )
}
