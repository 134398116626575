import { useStyles } from "./Home.css";
import HomeIcon from '@material-ui/icons/Home';

export const Home = () => {
  const classes = useStyles();
  return (<>
    <div className={classes.Header}><h2>Entreprises et actualités de la ZIPLO</h2><HomeIcon /></div>
    <p>Ce site répertorie la plupart des entreprises et des actualités de la zone industrielle de Plan les Ouates (ZIPLO). </p>
  </>);
}
