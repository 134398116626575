import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Menu } from './components/menu/Menu'
import { Home } from './pages/home/Home'
import { Actualites } from './pages/actualites/Actualites'
import { Compagnies } from './pages/compagnies/Compagnies'
//import { ConnexionForm } from "./pages/connexion/ConnexionForm";
import './App.css'

export default function App() {
  return (
    <Router>
      <div>
        <Menu />
        <Switch>
          <Route path="/actualites">
            <Actualites />
          </Route>
          <Route path="/compagnies">
            <Compagnies />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  )
}
