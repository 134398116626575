import axios from 'axios'

/* const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin' : '*',
    'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With"
  }
} */

//.get(`http://srv-mobilysweb:8888/charly/api?action=get`) //MI 02.09.2021 utilisé dans Actualites.js
export const getRandomFiche = async (table) => {
  return await axios
    .get(`/api/get`, { params: { tableName: table } })
    .then((res) => res.data)
}

// export const getRandomFicheFromTable = async (table) => {
//   console.log('getRandomFicheFromTable2 =====>>>>', table)

//   return await axios
//     .get(`/api/table`, { params: { tableName: table } })
//     .then((res) => res.data)
// }

// export const insertRandomFiche = async (quoi, sourceinformation, datesaisie, dateecheance, remarque, statut) => {
//   return await axios
//     .post(
//       `http://localhost:3002/api/insert`,
//       `action=update&Titre=${quoi}&Sourceinformation=${sourceinformation}`
//     )
//     .then((res) => res.data);
// };

export const insertRandomFiche = async (nom, prenom) => {
  return await axios
    .post(
      `http://srv-mobilysweb:8888/charly/api?`,
      `action=update&nom=${nom}&prenom=${prenom}`,
    )
    .then((res) => res.data)
}

export const updateRandomFiche = async (id, nom, prenom) => {
  return await axios
    .post(
      `http://srv-mobilysweb:8888/charly/api?`,
      `action=update&id=${id}&nom=${nom}&prenom=${prenom}`,
    )
    .then((res) => res.data)
}
export const deleteRandomFiche = async (id) => {
  return await axios.delete(
    `http://srv-mobilysweb:8888/charly/api?action=delete&id=${id}`,
  )
}
