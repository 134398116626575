import Modal from '@material-ui/core/Modal'
import {Card, Grid} from '@material-ui/core'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './FicheModal.css'
import { useState } from 'react'
import moment from 'moment'

const FicheModalCompagnies = ({
  isDetailsOpen,
  setIsDetailsOpen,
  addFiche,
  editFiche,
  currentFiche,
  setCurrentFiche,
  setNewFiche,
  tableFiche, //MI 08.02.23
  setSearchText,
}) => {
  const classes = useStyles()
  const [quoi, setQuoi] = useState(currentFiche ? currentFiche.quoi : '')
  const [secteur, setSecteur] = useState(
    currentFiche ? currentFiche.secteur : '',
  )
  const [datesaisie, setDSaisie] = useState(
    currentFiche ? currentFiche.DateSaisie : moment().format('DD.MM.YYYY'),
  )
  const [dateecheance, setDEcheance] = useState(
    currentFiche ? currentFiche.dateecheance : '',
  )
  const [remarque, setRemarque] = useState(
    currentFiche ? currentFiche.remarque : '',
  )
  const [statut, setStatut] = useState(currentFiche ? currentFiche.statut : '')
  const [siteinternet, setSiteinternet] = useState(currentFiche ? currentFiche.siteinternet : '')
  const [adresse, setAdresse] = useState(currentFiche ? currentFiche.adresse : '')
  const [telephone, setTelephone] = useState(currentFiche ? currentFiche.telephone : '') 
  const [complement, setComplement] = useState(currentFiche ? currentFiche.complement : '') 
  const [nbemploye, setNbEmploye] = useState(currentFiche ? currentFiche.nbemploye : 0) 
  //const [montantcotisation, setMontantcotisation] = useState(currentFiche ? currentFiche.montantcotisation : 0) 
  const [dateadhesion, setDateadhesion] = useState(
    currentFiche ? currentFiche.dateadhesion : moment().format('DD.MM.YYYY'), //
  )
  const [emailcontact, setEmailcontact] = useState(currentFiche ? currentFiche.emailcontact : '') 
  //const [titrecontact, setTitrecontact] = useState(currentFiche ? currentFiche.titrecontact : '')  
  const [nomcontact, setNomcontact] = useState(currentFiche ? currentFiche.nomcontact : '') 
  const [prenomcontact, setPrenomcontact] = useState(currentFiche ? currentFiche.prenomcontact : '') 
 
  

  const handleClose = () => {
    setIsDetailsOpen(false)
    setCurrentFiche(null)
    setNewFiche(false)
    setSearchText('')
  }

  const save = () => {
    console.log('Save la fiche de la table',tableFiche)
   // let ficheAdd = currentFiche
    console.log('tableFicheEAdd', tableFiche)
    //editPers.id = id;
    // ficheAdd.quoi = quoi
    // ficheAdd.secteur = secteur
    // ficheAdd.siteinternet = siteinternet
    // ficheAdd.adresse = adresse
    // ficheAdd.telephone = telephone
    // ficheAdd.complement = complement
    // ficheAdd.dateadhesion = dateadhesion
    // ficheAdd.emailcontact = emailcontact
    // ficheAdd.nomcontact = nomcontact
    // ficheAdd.prenomcontact = prenomcontact
    // ficheAdd.nbemploye = nbemploye
    // ficheAdd.datesaisie = datesaisie
    // ficheAdd.dateecheance = dateecheance
    // ficheAdd.remarque = remarque
    // ficheAdd.statut = statut
    // addFiche(ficheAdd,tableFiche)
    addFiche({ quoi,
    secteur,
    siteinternet,
    adresse,
    telephone,
    complement,
    dateadhesion,
    emailcontact,
    nomcontact,
    prenomcontact,
    nbemploye,
    datesaisie,
    dateecheance,
    remarque,
    statut},tableFiche)

    handleClose()
  }

  const edit = (e) => {
    let ficheEdition = currentFiche
    console.log('tableFicheE', tableFiche)
    //editPers.id = id;
    ficheEdition.quoi = quoi
    ficheEdition.secteur = secteur
    ficheEdition.siteinternet = siteinternet
    ficheEdition.adresse = adresse
    ficheEdition.telephone = telephone
    ficheEdition.complement = complement
    ficheEdition.dateadhesion = dateadhesion
    ficheEdition.emailcontact = emailcontact
    ficheEdition.nomcontact = nomcontact
    ficheEdition.prenomcontact = prenomcontact
    ficheEdition.nbemploye = nbemploye
    ficheEdition.datesaisie = datesaisie
    ficheEdition.dateecheance = dateecheance
    ficheEdition.remarque = remarque
    ficheEdition.statut = statut
    editFiche(ficheEdition, tableFiche) //MI 08.02.23

    //console.log("Quoi:"+quoi, "Remarque: "+sourceinformation);

    handleClose()
  }

  const body = (
    <Card className={classes.root}>
      <CardContent>
        <Grid container ignItems="flex-start" spacing={1}>
        <Grid item xs={10}>
        <Typography variant="h5" component="h2">
          {currentFiche ? 'Modifier' : 'Ajouter'} une{' '}
          {tableFiche === 'ACTUALITE' ? 'actualité' : 'entreprise'}{' '}
        </Typography>
           </Grid>
        <Grid item xs={2}>
        <Typography
          //variant="body1"
          //component="body1"
          className={classes.InfoDev}
        >
          {currentFiche ? '[' + currentFiche.id + ']' : ''}
        </Typography>
       </Grid>
        <Grid item xs={8}>
        <TextField
          autoFocus
          margin="dense"
          id="quoi"
          label="Qui"
          fullWidth
          value={quoi} //{currentFiche ? currentFiche.quoi : ""}
          onChange={(e) => setQuoi(e.currentTarget.value)}
          type="text"
        />
        </Grid>
         <Grid item xs={4}>
        <TextField
          margin="dense"
          id="secteur"
          label="Secteur"
          fullWidth
          value={secteur}
          onChange={(e) => setSecteur(e.currentTarget.value)}
          //defaultValue={currentFiche ? currentFiche.sourceinformation : ""}
          type="text"
        />
        </Grid>
        <Grid item xs={12}>
        <TextField
          margin="dense"
          id="adresse"
          label="Adresse"
          fullWidth
          value={adresse}
          onChange={(e) => setAdresse(e.currentTarget.value)}
          type="text"
         
        />
        </Grid>
         <Grid item xs={8}>
          <TextField
          margin="dense"
          id="siteinternet"
          label="Site internet"
          fullWidth
          value={siteinternet}
          onChange={(e) => setSiteinternet(e.currentTarget.value)}
          type="text"
        
        />
        </Grid>
        <Grid item xs={4}>
          <TextField
          margin="dense"
          id="telephone"
          label="Téléphone"
          value={telephone}
          fullWidth
          onChange={(e) => setTelephone(e.currentTarget.value)}
          type="text"
          display="none"
        />
        </Grid>

        <Grid item xs={4}>
        <TextField
          margin="dense"
          id="nomcontact"
          label="Nom Contact"
          value={nomcontact}
          fullWidth
          onChange={(e) => setNomcontact(e.currentTarget.value)}
          //defaultValue={currentFiche ? currentFiche.statut : ""}
          type="text"
        />
        
        </Grid>
        <Grid item xs={4}>
        <TextField
          margin="dense"
          id="prenomcontact"
          label="Prenom Contact"
          value={prenomcontact}
          fullWidth
          onChange={(e) => setPrenomcontact(e.currentTarget.value)}
          //defaultValue={currentFiche ? currentFiche.statut : ""}
          type="text"
        />
        
        </Grid>
        <Grid item xs={4}>
        <TextField
          margin="dense"
          id="emailcontact"
          label="Email"
          value={emailcontact}
          fullWidth
          onChange={(e) => setEmailcontact(e.currentTarget.value)}
          //defaultValue={currentFiche ? currentFiche.statut : ""}
          type="text"
        />   
        </Grid>
        <Grid item xs={4}>
        <TextField
          margin="dense"
          id="dateadhesion"
          label="Date adhésion"
          value={dateadhesion}
          fullWidth
          onChange={(e) => setDateadhesion(e.currentTarget.value)}
          type="text"
        />
        </Grid> 
        <Grid item xs={12}>
        <TextField
          margin="dense"
          id="complement"
          label="Remarque"
          fullWidth
          value={complement}
          onChange={(e) => setComplement(e.currentTarget.value)}
          // defaultValue={currentFiche ? currentFiche.remarque : ""}
          type="text"
        />
        </Grid>
                <Grid item xs={6}>
        <TextField
          margin="dense"
          id="nbemploye"
          label="Nb Emp."
          fullWidth
          value={nbemploye}
          onChange={(e) => setNbEmploye(e.currentTarget.value)}
          // defaultValue={currentFiche ? currentFiche.remarque : ""}
          type="text"
        />
        </Grid>
  </Grid>
      </CardContent>
      <CardActions className={classes.CardActions}>
        {currentFiche ? (
          <Button size="small" onClick={edit}>
            Modifier
          </Button>
        ) : (
          <Button size="small" onClick={save}>
            Enregistrer
          </Button>
        )}
        <Button color="secondary" size="small" onClick={handleClose}>
          Annuler
        </Button>
      </CardActions>
    </Card>
  )

  return (
    <div>
      <Modal
        open={isDetailsOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  )
}
export default FicheModalCompagnies
