import Modal from '@material-ui/core/Modal'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './FicheModal.css'
import { useState } from 'react'
import moment from 'moment'

const FicheModal = ({
  isDetailsOpen,
  setIsDetailsOpen,
  addFiche,
  editFiche,
  currentFiche,
  setCurrentFiche,
  setNewFiche,
  tableFiche, //MI 08.02.23
  setSearchText,
}) => {
  const classes = useStyles()
  const [quoi, setQuoi] = useState(currentFiche ? currentFiche.quoi : '')
  const [sourceinformation, setSource] = useState(
    currentFiche ? currentFiche.sourceinformation : '',
  )

  const [datesaisie, setDSaisie] = useState(
    currentFiche ? currentFiche.datesaisie : moment().format('DD.MM.YYYY'),
  )
  const [dateecheance, setDEcheance] = useState(
    currentFiche ? currentFiche.dateecheance : '',
  )
  const [remarque, setRemarque] = useState(
    currentFiche ? currentFiche.remarque : '',
  )
  const [statut, setStatut] = useState(currentFiche ? currentFiche.statut : '')

 
  

  const handleClose = () => {
    setIsDetailsOpen(false)
    setCurrentFiche(null)
    setNewFiche(false)
    setSearchText('')
  }

  const save = () => {
    addFiche({
      quoi,
      sourceinformation,
      datesaisie,
      dateecheance,
      remarque,
      statut,
    },tableFiche)

    handleClose()
  }

  const edit = (e) => {
    let ficheEdition = currentFiche
    console.log('tableFicheA', tableFiche)
    //editPers.id = id;
    ficheEdition.quoi = quoi
    ficheEdition.sourceinformation = sourceinformation
    ficheEdition.datesaisie = datesaisie
    ficheEdition.dateecheance = dateecheance
    ficheEdition.remarque = remarque
    ficheEdition.statut = statut
    editFiche(ficheEdition, tableFiche) //MI 08.02.23

    //console.log("Quoi:"+quoi, "Remarque: "+sourceinformation);

    handleClose()
  }

  const body = (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h5" component="h2">
          {currentFiche ? 'Modifier' : 'Ajouter'} une{' '}
          {tableFiche === 'ACTUALITE' ? 'actualité' : 'entreprise'}{' '}
        </Typography>
        <Typography
          //variant="body1"
          //component="body1"
          className={classes.InfoDev}
        >
          {currentFiche ? '[' + currentFiche.id + ']' : ''}
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          id="quoi"
          label="Quoi"
          fullWidth
          value={quoi} //{currentFiche ? currentFiche.quoi : ""}
          onChange={(e) => setQuoi(e.currentTarget.value)}
          type="text"
        />
        <TextField
          margin="dense"
          id="source"
          label="Source"
          fullWidth
          value={sourceinformation}
          onChange={(e) => setSource(e.currentTarget.value)}
          //defaultValue={currentFiche ? currentFiche.sourceinformation : ""}
          type="text"
        />
        <TextField
          margin="dense"
          id="datesaisie"
          label="Date saisie"
          value={datesaisie}
          onChange={(e) => setDSaisie(e.currentTarget.value)}
          // defaultValue={currentFiche ? currentFiche.datesaisie : ""}
          type="text"
        />
        <TextField
          margin="dense"
          id="dateecheance"
          label="Echeance"
          value={dateecheance}
          onChange={(e) => setDEcheance(e.currentTarget.value)}
          //defaultValue={currentFiche ? currentFiche.dateecheance : ""}
          type="text"
        />
        <TextField
          margin="dense"
          id="remarque"
          label="Remarque"
          fullWidth
          value={remarque}
          onChange={(e) => setRemarque(e.currentTarget.value)}
          // defaultValue={currentFiche ? currentFiche.remarque : ""}
          type="text"
        />
        <TextField
          margin="dense"
          id="statut"
          label="Statut"
          value={statut}
          onChange={(e) => setStatut(e.currentTarget.value)}
          //defaultValue={currentFiche ? currentFiche.statut : ""}
          type="text"
        />
    
      </CardContent>
      <CardActions className={classes.CardActions}>
        {currentFiche ? (
          <Button size="small" onClick={edit}>
            Modifier
          </Button>
        ) : (
          <Button size="small" onClick={save}>
            Enregistrer
          </Button>
        )}
        <Button color="secondary" size="small" onClick={handleClose}>
          Annuler
        </Button>
      </CardActions>
    </Card>
  )

  return (
    <div>
      <Modal
        open={isDetailsOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  )
}
export default FicheModal
