import React, { useState, useEffect, Fragment } from 'react'
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"
import { Button } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import Divider from '@material-ui/core/Divider'

export default function MyDialog(...params) {
  let p = {}  //Les paramètres sont dans 1er élément de l'array ???
  Object.keys(params[0]).forEach((key) => p[key] = params[0][key]) //Copie de l'objet[0], car il est en read-only.
  //console.log('params=', params)
  const [captcha, setCaptcha] = useState('')
  const [input, setInput] = useState('')
  const [captchaRef, setCaptchaRef] = useState('')
  const [other, setOther] = useState({})

  p.captchaSet = p.captchaSet ?? false
  p.inputSet = p.inputSet ?? false
  p.other = p.other ?? {}   //Pour passer des autres paramètres entre l'appel du dialog, et le callback.
  useEffect(() => {
    setCaptcha('')
    setCaptchaRef(randomString(6))  //6= nombre de caractères dans le captcha.
    setInput('')
    setOther(p.other)               //Autres paramètres à retourner dans le callback.
  }, [p.captchaSet, p.inputSet])

  p.text = p.text ?? ''
  if (p.text ==='') return null //Blindage pour fermer le dialogue.
  let autre = p.other   //Correction bug reset attribut other si inputSet=false.

  p.title = p.title ?? ''
  p.title = p.title ?? ''
  p.inputRows = p.inputRows ?? 1                        //Nombre de lignes affichées dans input.
  p.btn1 = p.btn1 || (p.captchaSet ? 'Valider' : 'Ok')  //Ok par défaut, ou "Valider" s'il faut un captcha.
  p.btn2 = p.btn2 || (p.captchaSet ? 'Annuler' : '')    //Masqué par défaut, ou "Annuler" s'il y a un captcha.
  p.id = p.id ?? ''
  
  function handleClick(e, retour) {
    e.preventDefault()
    //console.log('handleClick=', retour)
    p.onUp(retour)
  }

  return (
    <div>
      <Dialog
        open={p.text !==''}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">{p.title}</DialogTitle>
        <DialogContent>
          <DialogContentText component="span"> {/*"span" pour corriger bug react 'validateDOMnesting(...): <div> cannot appear as a descendant of <p>' */}
              <>
                {p.text}
                {p.inputSet ? 
                  <TextField
                    autoFocus={p.inputSet}
                    color='primary'
                    variant={p.inputRows > 1 ? 'outlined' : 'standard'}
                    value={input}
                    onChange={(e)=> setInput(e.target.value)}
                    fullWidth={p.inputRows > 1}
                    multiline={p.inputRows > 1}
                    rows={p.inputRows}
                  >
                  </TextField>
                : null }
              </>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {p.captchaSet ? 
            <> 
              <Divider/>   
              <TextField 
                autoFocus={!p.inputSet}
                error
                margin="dense"
                color='primary'
                variant='outlined'
                label={!p.inputSet ? `Saisir le captcha: ${captchaRef}` : ''}
                helperText={p.inputSet ? `Saisir le captcha: ${captchaRef}` : ''}
                value={captcha}
                onChange={(e) => setCaptcha(e.target.value)}
                >
              </TextField>
            </>
          : null }

          {p.btn2 !=='' ? 
            <Button 
              onClick={(e)=>handleClick(e, {id: p.id, button: '2', inputText: input, other: autre})} color="primary"
              >{p.btn2}
            </Button>
          : null }

          <Button 
            disabled= {p.captchaSet && captcha !== captchaRef}
            onClick={(e)=>handleClick(e, {id: p.id, button: '1', inputText: input, other: autre})} color="primary" variant="contained"
            >{p.btn1}
          </Button>

        </DialogActions>
      </Dialog>
    </div>
  )
}


function randomString(maxCars) {
  const cars = 'ABCDEFGHJKLMNPQRSTUVWXYZabcedfghjkmnopqrstuvwxyz23456789' //Supprimé certains caractères au visuel identique.
  const carsLength = cars.length
  let result = ''
  for ( let i = 0; i < maxCars; i++ ) {
    result += cars.charAt(Math.floor(Math.random() * carsLength))
  }
  return result
}
