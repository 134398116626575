import { Link as RouterLink } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

import { useStyles } from './Menu.css'

export const Menu = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">ZIPLO</Typography>
          <div className={classes.Links}>
            <Link
              className={classes.Link}
              component={RouterLink}
              color="inherit"
              to="/"
            >
              Accueil
            </Link>
            <Link
              className={classes.Link}
              component={RouterLink}
              color="inherit"
              to="/compagnies"
            >
              ENTREPRISES
            </Link>
            <Link
              className={classes.Link}
              component={RouterLink}
              color="inherit"
              to="/actualites"
            >
              ACTUALITES
            </Link>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  )
}
