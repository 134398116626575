import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    Links: {
        flex:1,
        textAlign: "center",
        marginRight:"10vw"
    },
    Link:{
        marginRight:"3vw",
    }

}));