import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    marginTop: "25vh",
    width: "60vw",
  },
  CardActions: {
    justifyContent: "flex-end",
  },
  InfoDev: {
    textAlign: "right",
    color: "grey",
    textSizeAdjust: "4px",
  },
}));
