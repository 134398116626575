import { useStyles } from './ListFiches.css'
import { DataGrid, GridToolbar } from '@material-ui/data-grid'
import { Button } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import moment from 'moment'
import { useState, useEffect, Fragment } from 'react'
import FicheModal from '../../modals/FicheModal/FicheModal'
import FicheModalCompagnies from '../../modals/FicheModal/FicheModalCompagnies'
import Axios from 'axios'
import MyDialog from '../../modals/MyDialog'

export const ListFiches = ({
  setCurrentFiche,
  // setIsDetailsOpen,
  deleteRandomPerson,
  getFiches,
  persons,
  currentFiche,
  setFicheEdited,
  setNewFiche,
  tableFiche,
  setSearchText,
  //columns,
}) => {
  const classes = useStyles()
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)
  //const [currentFiche, setCurrentFiche] = useState(setCurrentFiche);
  const [myDialog, setMyDialog] = useState({}) //Pour appeler le dialogue.
  const MyDialogReturn = (e) => {
    //Pour gérer les infos de retour.
    if (e.id === 'confirmeDelete') {
      console.log('confirme delete')
      if (e.button === '1') {
        deleteRandomPerson(e.other.id, tableFiche)
        console.log(e)
      } //console.log('Le bouton principal est cliqué')
      setMyDialog({ text: '' }) //Pour fermer le dialogue.
    }
  }

  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.4 },
    { field: 'quoi', headerName: tableFiche === 'COMPAGNIES' ? 'Qui' :'Quoi', flex: 1 },
    { field: tableFiche === 'COMPAGNIES' ? 'secteur' :'sourceinformation', headerName: tableFiche === 'COMPAGNIES' ? 'Secteur' :'Source', flex: 0.75 },
    { field: tableFiche === 'COMPAGNIES' ? 'adresse' :'datesaisie', headerName: tableFiche === 'COMPAGNIES' ? 'Adresse' :'Saisie le', flex: 1 },
    { field: tableFiche === 'COMPAGNIES' ? 'telephone' :'dateecheance', headerName: tableFiche === 'COMPAGNIES' ? 'Telephone' :'Date echeance', flex: 0.75 }, //flex: 1
    { field: tableFiche === 'COMPAGNIES' ? 'siteinternet' :'remarque', headerName: tableFiche === 'COMPAGNIES' ? 'Site' :'Remarque', flex: 0.75 },
    { field: tableFiche === 'COMPAGNIES' ? 'nomcontact' : 'statut', headerName: tableFiche === 'COMPAGNIES' ? 'Nom Cont.' :'Statut', flex: 0.6 },
    { field: tableFiche === 'COMPAGNIES' ? 'prenomcontact' : null, headerName: tableFiche === 'COMPAGNIES' ? 'Prenom Cont.' : null, flex: 0.5 },
    { field: tableFiche === 'COMPAGNIES' ? 'emailcontact' : null, headerName: tableFiche === 'COMPAGNIES' ? 'Email' :null, flex: 0.5 },
    { field: tableFiche === 'COMPAGNIES' ? 'dateadhesion' :null, headerName: tableFiche === 'COMPAGNIES' ? 'Date Adh.' :null, flex: 0.6 },
    { field: tableFiche === 'COMPAGNIES' ? 'complement' :null, headerName: tableFiche === 'COMPAGNIES' ? 'Remarque' :null, flex: 0.55 },
    { field: tableFiche === 'COMPAGNIES' ? 'nbemploye' :null, headerName: tableFiche === 'COMPAGNIES' ? 'Nb Emp.' :null, flex: 0.5 },
    {
      field: '',
      headerName: 'Actions',
      disableClickEventBubbling: true,
      width: 'auto', //
      flex: 0.75,
      renderCell: (params) => {
        const onEdit = () => {
          const api = params.api
          const fields = api.getAllColumns().map((c) => c.field)
          const thisRow = {}

          fields.forEach((f) => {
            if (f !== '') {
              //  if(f === "datesaisie"){
              //   thisRow[f] = moment(params.getValue(f)).format("DD.MM.YYYY");
              //  }else{
              //   thisRow[f] = params.getValue(f);
              //  }

              thisRow[f] = params.getValue(f)
            }
          })

          editLine(thisRow)
        }

        const onDelete = () => {
          const api = params.api
          const fields = api.getAllColumns().map((c) => c.field)
          const thisRow = {}
          fields.forEach((f) => {
            thisRow[f] = params.getValue(f)
          })

          console.log('')
          deleteLine(thisRow.id, thisRow.quoi)
        }
        return (
          <>
            <Button color="primary" onClick={onEdit}>
              <EditIcon />
            </Button>
            <Button color="secondary" onClick={onDelete}>
              <DeleteIcon color="secondary" />
            </Button>
          </>
        )
      },
    },
  ]


// if(tableFiche === 'COMPAGNIES'){
// //columns.splice(2, 1, { field: 'secteur', headerName: 'Secteur', flex: 0.2 });
// //columns.splice(3, 1, { field: 'adresse', headerName: 'Adresse', flex: 0.2 });
// //columns.splice(4, 1, { field: 'telephone', headerName: 'Telephone', flex: 0.2 });
// //columns.splice(5, 1, { field: 'siteInternet', headerName: 'Site', flex: 0.2 });
// //columns.splice(6, 1, { field: 'dateAdhesion', headerName: 'Date adhésion', flex: 0.2}); 
// //columns.splice(7, 1, { field: 'nomContact', headerName: 'Nom contact', flex: 0.2 });
// //columns.splice(8, 1, { field: 'prenomContact', headerName: 'Prenom contact', flex: 0.4 });
// //columns.splice(9, 1, { field: 'emailContact', headerName: 'Email contact', flex:0.4 });
// //columns.splice(10, 1, { field: 'nbEmploye', headerName: 'Nombre employé', flex: 0.2 });

// }
  useEffect(() => {
    setNewFiche(false)
    
  }, [])
  const editLine = (pers) => {
    setCurrentFiche(pers)
    setIsDetailsOpen(true)
    //setQuoi(pers.quoi);
    console.log(pers)
  }

  const deleteLine = (id, quoi) => {
    setMyDialog({
      id: 'confirmeDelete',
      text: "Attention! Voulez-vous VRAIMENT supprimer "+(tableFiche === 'COMPAGNIES' ? "l'entreprise: [" :"l'actualité: [")+ quoi + ']?', //(tableFiche === 'COMPAGNIES' ? "entreprise" :'actualité')
        
      btn1: 'Supprimer',
      btn2: 'Annuler',
      other: { id: id, table: tableFiche},
    })
  }

  const editFiche = (pers, table) => {
    console.log('table', table, moment(pers.dateadhesion,'DD.MM.YYYY').format('YYYY-DD-MM HH:mm:ss')) //MI 08.02.23 
    Axios.put('/api/update', {
      id: pers.id,
      quoi: pers.quoi,
      sourceinformation: pers.sourceinformation,
      secteur: pers.secteur,
      adresse: pers.adresse,
      nomcontact: pers.nomcontact,
      prenomcontact: pers.prenomcontact,
      emailcontact: pers.emailcontact,
      siteinternet : pers.siteinternet,
      telephone: pers.telephone,
      datesaisie: moment(pers.datesaisie,'DD.MM.YYYY').format('YYYY-MM-DD'),
      dateadhesion: moment(pers.dateadhesion,'DD.MM.YYYY').format('YYYY-MM-DD'),
      dateecheance: pers.dateecheance, 
      complement: pers.complement,
      remarque: pers.remarque,
      statut: pers.statut,
      nbemploye: pers.nbemploye,
      table: table,
    }).then((response) => {
      
      getFiches()
      //console.log(setSearchText)
      alert('Modification effectuée')
    
    })
  }

  return (
    <Fragment>
      

      <MyDialog {...myDialog} onUp={(e) => MyDialogReturn(e)} />
      <div className={classes.GridContainer} style={{ width: '100%' }}>
        <DataGrid 
          rows={persons} 
          columns={columns} 
          components={{
          Toolbar: GridToolbar,
         }}
        />

        {currentFiche && (
          tableFiche === 'ACTUALITE'? 
          <FicheModal
            isDetailsOpen={isDetailsOpen}
            setIsDetailsOpen={setIsDetailsOpen}
            editFiche={editFiche}
            currentFiche={currentFiche}
            setCurrentFiche={setCurrentFiche}
            setNewFiche={setNewFiche}
            tableFiche={tableFiche} //MI 08.02.23
            setSearchText={setSearchText}
          /> :  <FicheModalCompagnies
            isDetailsOpen={isDetailsOpen}
            setIsDetailsOpen={setIsDetailsOpen}
            editFiche={editFiche}
            currentFiche={currentFiche}
            setCurrentFiche={setCurrentFiche}
            setNewFiche={setNewFiche}
            tableFiche={tableFiche} //MI 08.02.23
            setSearchText={setSearchText}
          />

        )}
      </div>
    </Fragment>
  )
}

export default ListFiches
